<template>
    <Layout>
        <h4>New Circular</h4>
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="createCircular"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Title</label>
                        <input
                            v-model="circular.title"
                            type="text"
                            class="form-control mb-3"
                            id="title"
                            :class="{
                                'is-invalid':
                                    submitted && $v.circular.title.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.circular.title.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.circular.title.required"
                                >Title is required</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname">Attachment</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleAttachment"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf,image/jpg"
                        :class="{
                            'is-invalid':
                                submitted && $v.circular.attachment.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.circular.attachment.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.circular.attachment.required"
                            >Attachment file is required</span
                        >
                    </div>
                </div>
            </div>
            <submitButton />
        </form>
    </Layout>
</template>
<script>
/* eslint-disable no-unused-vars */

import Layout from "../../layouts/main.vue";
import submitButton from "../../../components/submitButton.vue";
import alertMixin from "../../../mixins/alertMixin";
import { BASE_URL } from "../../common";
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
    mixins: [alertMixin],
    components: {
        Layout,
        submitButton,
    },
    validations: {
        circular: {
            title: { required },
            attachment: { required },
        },
    },
    data() {
        return {
            submitted: false,
            fullPage: false,
            userRole: null,
            circular: {
                title: "",
                attachment: "",
                attachment_filename: "",
            },
        };
    },
    mounted() {
        if (
            localStorage.cs_user_role != "Administrator" &&
            localStorage.cs_user_role != "Archiving"
        ) {
            this.$router.push({
                path: "/",
            });
        }
    },
    methods: {
        createCircular() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}circulars?token=${localStorage.cs_user_token}`,
                            this.circular
                        )
                        .then((res) => {
                            if (res.data.status_code === 200) {
                                this.successPopUp("New circular created ");
                                this.resetFields();
                            }
                            loader.hide();
                        })
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
        handleAttachment(e) {
            let reader = new FileReader();
            this.circular.attachment_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.circular.attachment = e.target.result.split(",")[1];
                console.log(this.circular.attachment);
            };
            reader.readAsDataURL(e.target.files[0]);
        },
    },
};
</script>
<style scoped></style>
